import {useAuth0} from "@auth0/auth0-react";
import axios from "axios";

export const AuthMethods = () => {
    const {isAuthenticated, getAccessTokenSilently} = useAuth0();

    const getToken = async () => {
        try {
            if (isAuthenticated) {
                const accessToken = await getAccessTokenSilently();
                return accessToken;
            }
        } catch (error) {
            console.error('Error fetching access token', error);
        }
    };

    const getRoles = (accessToken, user_id) => {
        let role = [];

        let requestConfig = {
            headers: {
                authorization: `${accessToken}`,
                "content-type": "application/json"
            },
        };

        return fetch( `/api/user/roles/${user_id}`, requestConfig)
            .then( res => res.json() )
            .then( rolesArr => {
                try {
                    if ( rolesArr.length > 0 ) {
                        rolesArr.forEach( roleItem => {
                            if ( roleItem.name.includes( "AIREDALE_" ) )
                                role.push( roleItem.name.split('_').pop() );
                        });
                    }

                    if ( role.length === 0 ) {
                         role.push('rol_dWCNMzKGSHLnOilC');
                         //addDefaultRole(user_id) // no roles assigned, we assign it the default role
                        //window.location.reload()
                    }

                    return role;
                } catch ( rolesRespErr ) {
                    console.error( rolesRespErr );
                }
            });
    }


    const getUserIdentities = async (accesstoken, userId) => {
        let headers = {"Accept": "application/json", "Authorization": `Bearer ${accesstoken}`};
        let requestOptions = {
            method: 'GET',
            headers: headers,
        };

        return fetch(`/api/user/identities/${userId}`, requestOptions)
            .then(function(response) {
                return response.json();
            }).then(function(json) {
                return json;
            })
            .catch(error => console.log('error', error));
    }

    const linkAccount = (accessToken, userId) => {
        let headers = { "Authorization": `Bearer ${accessToken}`, "Accept": "application/json", "Content-Type": "application/json"};

        let requestOptions = {
            method: 'POST',
            headers: headers
        };

        return fetch(`api/user/link-account/${userId}`, requestOptions)
            .then(response => response.text())
            .catch(error => console.log('error', error));
    }

    const addDefaultRole = (userId, defaultRole = 'rol_dWCNMzKGSHLnOilC') => {
        axios
            .post(`https://futureplc.eu.auth0.com/api/v2/users/${userId}/roles`, {'roles': [defaultRole]})
            .then(response => response.data)
            .then((response) => {
                if (response.error) {
                    console.log('error, couldn\'t assign role')
                }
            })
            .catch((thrown) => {
                if (axios.isCancel(thrown)) {
                    console.log('Request canceled', thrown.message);
                } else {
                    console.log(thrown);
                }
            });
    }

    return {
        getRoles,
        getToken,
        getUserIdentities,
        linkAccount,
        addDefaultRole
    };
}