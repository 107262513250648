import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userLogin } from 'react-admin';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Authv2 from './Authv2';
import {Auth0Provider} from "@auth0/auth0-react";

const theme = createMuiTheme({
    palette: {
        type: 'light',
    },
});

const styles = them => ({
    progress: {
        margin: them.spacing.unit * 2,
    },
    loader: {
        textAlign: 'center',
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
    }
});

class LoginPage extends Component {
    render() {
        const { classes } = this.props;
        return (
            <MuiThemeProvider theme={theme}>
                <CssBaseline />
                <div className={classes.loader}>
                    <Typography variant="headline" gutterBottom>
                        <Auth0Provider
                            domain={window.auth_config.domain}
                            clientId={window.auth_config.clientID}
                            redirectUri={window.location.origin+'/'}
                            authorizationParams={{
                                redirect_uri: window.location.origin,
                                audience: 'https://futureplc.eu.auth0.com/userinfo'
                            }}
                        >
                            <Authv2 callback={this.props.userLogin} />
                        </Auth0Provider>
                    </Typography>
                </div>
            </MuiThemeProvider>
        )
    }
}

export default connect(undefined, { userLogin })(withStyles(styles)(LoginPage));