import {useAuth0} from "@auth0/auth0-react";
import React, {useEffect, useState} from "react";
import { AuthMethods } from './AuthMethods';

const Authv2 = (props) => {
    const {getToken, linkAccount, getRoles} = AuthMethods();
    const {loginWithRedirect, isAuthenticated, isLoading, user} = useAuth0();

    const [accessToken, setAccessToken] = useState(null);

    useEffect(() => {
        if (!isLoading && !isAuthenticated) {
            loginWithRedirect({
                authorizationParams: {
                    redirect_uri: window.location.origin
                }
            })
        }
    }, [isLoading, isAuthenticated, loginWithRedirect]);

    if (isAuthenticated && user && accessToken === null) {
        getToken().then(accessTokenReturn => setAccessToken(accessTokenReturn));
    }

    if (accessToken) {
        let role = [];

        const getRolePromise =  getRoles(accessToken, user.sub);
        const linkAccountPromise = linkAccount(accessToken, user.sub);

        Promise.all([getRolePromise, linkAccountPromise]).then(r => {
            getRolePromise.then(returnedData => {
                role = returnedData;
                props.callback({
                    role: role,
                    token: accessToken,
                    user: user.sub,
                    expiresIn: 36000
                })
            });
        });

        return (
            <div>
                Connected, you are going to be redirected to the airedale application
            </div>
        );
    }

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return <></>;
}

export default Authv2;